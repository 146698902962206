import React, { useEffect, useState } from 'react';
import "./DanceClasses.scss";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const danceClassesList = [
    {
        name: "Pasion - Bachata pal bailador",
        image: "/images/pasion-bachata-pal-bailador.png",
        url: "https://thelatinpassion.co.uk/pasion-bachata-saturdays/"
    },
    {
        name: "Sensual Bachata Wednesdays with Peynao",
        image: "/images/sensual-bachata-wednesdays-with-peynao.png",
        url: "https://thelatinpassion.co.uk/sensual-bachata-classes-on-wednesdays/"
    },
    {
        name: "Funday Sunday Dance - Brighton",
        image: "/images/funday-sunday-dance.png",
        url: "https://www.facebook.com/BachataLife/"
    },
    {
        name: "Private dance classes with Peynao and Tina",
        image: "/images/private-dance-classes-with-peynao-tina.png",
        url: "https://thelatinpassion.co.uk/private-dance-classes-bachata-salsa-latin/"
    },
    {
        name: "Private Bachata Classes with Simon Lord Bachata",
        image: "/images/private-bachata-classes-with-simon.png",
        url: "https://www.facebook.com/simon.lordbachata"
    },
    {
        name: "Spice Amigos Mondays",
        image: "/images/spice-amigos-mondays.png",
        url: "https://thelatinpassion.co.uk/spice-amigos-mondays/"
    }
];

function getWindowWidth() {
    const { innerWidth: width } = window;
    console.log("width:", width);
    return width;
}

const DanceClasses = () => {

    const [slidesToShow, setSlidesToShow] = useState(5);
    /*
    */

    useEffect(() => {
        function handleResize() {
            if (getWindowWidth() >= 1000) {
                setSlidesToShow(5);
            } else if (getWindowWidth() > 800) {
                setSlidesToShow(4);
            } else if (getWindowWidth() > 600) {
                setSlidesToShow(3);
            } else {
                setSlidesToShow(2);
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [slidesToShow]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        fade: false,
        arrows: true,
        className: "slides",
        autoplay: true,
    }

    return (
        <div className="container__dance__classes">
            <h2>LATIN DANCE CLASSES IN THE UK</h2>
            <div className="slider__container">
                <Slider {...settings} className="slider">
                    {danceClassesList.map((danceClass) => {
                        return (
                            <div>
                                <a rel="noopener" href={danceClass.url} target="_blank"><img src={danceClass.image} alt={danceClass.name} /></a>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default DanceClasses
