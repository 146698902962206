import React, { useEffect, useState } from "react";
import "./Header.scss";

const Header = () => {
  const [burgerState, setBurgerState] = useState("menu-btn");
  const [navState, setNavState] = useState("mobile__navbar__closed");
  const [navBgState, setNavBgState] = useState("mobile__navbar__bg__off");
  const [scrolling, setscrolling] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);

  const handleClick = () => {
    if (burgerState === "menu-btn") {
      handleScrolling();
      setBurgerState("menu-btn open");
      setNavState("mobile__navbar__open");
      setNavBgState("mobile__navbar__bg__on");
    } else {
      handleScrolling();
      console.log("navBgState: ", navBgState);
      setBurgerState("menu-btn");
      setNavState("mobile__navbar__closed");
      setNavBgState("mobile__navbar__bg__off");
    }
  };

  const handleScrolling = () => {
    console.log("scrolling: ", !scrolling);
    setscrolling(!scrolling);
  };

  useEffect(() => {
    function onScroll() {
      if (scrolling === false) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "auto",
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop, scrolling]);

  /*
   */

  return (
    <div id="header__container">
      <div id="mobile-navbar-bg" className={navBgState}></div>
      {/* changed navbar from id to class*/}
      <nav className="navbar">
        {/* changed navbar from id name from __ to -*/}
        <a id="home-button" href="/home">
          <img id="logo-text" src="/images/logo-text.png" alt="BSAUKC" />
        </a>
        <div className={burgerState} onClick={handleClick}>
          <div className="menu-btn__burger"></div>
        </div>

        {/*
                 Large screen menu */}
        <ul>
          <li key="li-head-'01">
            <a href="/aboutus">ABOUT</a>
          </li>
          <li key="li-head-'02">
            <a href="/ourchampions">OUR CHAMPIONS</a>
          </li>
          <li key="li-head-'03">
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSf-4Vos7YtVM_s1uvvkbaNvPohsIqlXwiWZMSwjtVIH1x6FpA/viewform"
            >
              ENROLL NOW
            </a>
          </li>
          <li key="li-head-'04">
            <a href="/rules">RULES</a>
          </li>
          <li key="li-head-'05">
            <a href="/events">HEATS</a>
          </li>
          <li key="li-head-'06">
            <a href="/home">HOME</a>
          </li>
        </ul>
      </nav>
      {/* <ul id="small-screen-navbar" className={navState}>*/}
      <ul id="mobile-navbar" className={navState}>
        <li key="li-head-'11" className="r1">
          <a href="/home">HOME</a>
        </li>
        <li key="li-head-'12" className="r2">
          <a href="/events">HEATS</a>
        </li>
        <li key="li-head-'13" className="r3">
          <a href="/rules">RULES</a>
        </li>
        <li key="li-head-'14" className="r4">
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSf-4Vos7YtVM_s1uvvkbaNvPohsIqlXwiWZMSwjtVIH1x6FpA/viewform"
          >
            ENROLL NOW
          </a>
        </li>
        <li key="li-head-'15" className="r5">
          <a href="/ourchampions">OUR CHAMPIONS</a>
        </li>
        <li key="li-head-'16" className="r6">
          <a href="/aboutus">ABOUT</a>
        </li>
      </ul>
      {/*
            <div className="spacer"></div>
             */}
    </div>
  );
};

export default Header;
