import React, { useEffect, useState } from 'react';
import validateIsEmail from '../utils/validateIsEmail';
import "./JoiningForm.scss";

const JoiningForm = () => {
    const [events, setEvents] = useState([]);
    const [heat, setHeat] = useState("");
    const [fullName1, setFullName1] = useState("");
    const [nickName1, setNickName1] = useState("");
    const [phone1, setPhone1] = useState("");
    const [email1, setEmail1] = useState("");
    const [confirmEmail1, setConfirmEmail1] = useState("");
    const [facebook1, setFacebook1] = useState("");
    const [instagram1, setInstagram1] = useState("");
    const [fullName2, setFullName2] = useState("");
    const [nickName2, setNickName2] = useState("");
    const [phone2, setPhone2] = useState("");
    const [email2, setEmail2] = useState("");
    const [confirmEmail2, setConfirmEmail2] = useState("");
    const [facebook2, setFacebook2] = useState("");
    const [instagram2, setInstagram2] = useState("");
    const [agree, setAgree] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [congratsMessage, setCongratsMessage] = useState("");
    //const [warningMessages, setWarningMessages] = useState([]);



    const getEvents = async () => {
        try {
            const response = await fetch("https://test-website.uk/bsaukc-server/events");
            const jsonData = await response.json();

            setEvents(jsonData);
        } catch (error) {
            console.log(error.message);
        }
    };

    const onSubmitForm = async e => {
        e.preventDefault();

        let eMessages = [];
        setCongratsMessage("");
        //let wMessages = [];
        if (heat === "") {
            eMessages.push("Please, select a heat");
        }

        if (email1 !== confirmEmail1) {
            eMessages.push("Emails don't match for the First member");
        }

        if (!validateIsEmail(email1)) {
            eMessages.push("You didn't introduce a valid email for the first member");
        }

        if (email2 !== confirmEmail2) {
            eMessages.push("Emails don't match for the Second member");
        }

        if (fullName1 === fullName2) {
            eMessages.push("You must introduce a different full name for each member");
        }

        if (nickName1 === nickName2) {
            eMessages.push("You must introduce a different nickname for each member");
        }

        if (phone1 === phone2) {
            eMessages.push("You must introduce a different phone for each member");
        }

        if (email1 === email2) {
            eMessages.push("You must introduce a different email for each member");
        }

        if (agree === false) {
            eMessages.push("Please, agree to follow Bachata Spice ttp UK Championship's rules");
        }

        /*
        if (facebook1 === "" || facebook2 === "" || instagram1 === "" || instagram2 === "") {
            wMessages.push("It's not required but strongly recommendable to provide your social media contact information");
        }
        */

        setErrorMessages([...eMessages]);
        //setWarningMessages([...wMessages]);
        if (eMessages.length === 0) {

            try {
                const body = {
                    heatId: heat.substring(0, 1), heatTitleAndDate: heat.substring(2), fullName1, nickName1, phone1, email1, facebook1, instagram1,
                    fullName2, nickName2, phone2, email2, facebook2, instagram2, agree: agree ? 1 : 0
                };

                console.log("login body: ", body);
                //await fetch("https://" + window.location.hostname + ":5000/register-competitors", {
                await fetch("https://test-website.uk/bsaukc-server/register-competitors", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    //credentials: "same-origin",
                    body: JSON.stringify(body)
                })
                    .then(response => response.json())
                    .then(body => {
                        if (body.error) {
                            console.log("body: ", body);
                            setErrorMessages([body.error]);
                        } else {
                            console.log("SOMETHING HAPPENING");
                            setCongratsMessage(nickName1 + " & " + nickName2 + ", welcome Bachata Spice ttp UK Championship! Now it's time to get ready for the action. For any question you can always contact Peynao (contact details at the bottom of this page).");
                        }

                        setHeat("");
                        setFullName1("");
                        setNickName1("");
                        setPhone1("");
                        setEmail1("");
                        setConfirmEmail1("");
                        setFacebook1("");
                        setInstagram1("");
                        setFullName2("");
                        setNickName2("");
                        setPhone2("");
                        setEmail2("");
                        setConfirmEmail2("");
                        setFacebook2("");
                        setInstagram2("");
                        setAgree(false);


                    });
            } catch (err) {
                console.log("ERROR!!");
                setErrorMessages([err.message])
            }
        }
    }

    useEffect(() => {
        getEvents();
    }, []);

    return (
        <div className="container__joining__form">
            <h1>JOINING FORM</h1>
            <form onSubmit={onSubmitForm}>
                <div className="joining__form">
                    <p>Please, make sure you read the rules on this <a href="/rules">link</a> before joining the competition.</p>
                    <div>
                        <label htmlFor="heat">Your Qualifying Heat</label>
                        <select id="heat" onClick={(e) => { setHeat(e.target.value) }} required>
                            {
                                events.map((event, index) => {
                                    if (index < events.length - 1) {
                                        return <option key={event.id} value={event.id + "-" + event.name + " - " + new Date(event.starts).toDateString()}>{event.name + " - " + new Date(event.starts).toDateString()}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                    <h2>MEMBER 1</h2>
                    <div>
                        <label htmlFor="full-name1">* Full name</label>
                        <input type="text" id="full-name1" value={fullName1} onChange={(e) => { setFullName1(e.target.value) }} required />
                        <label htmlFor="nick-name1">* Nickname</label>
                        <input type="text" id="nick-name1" value={nickName1} onChange={(e) => { setNickName1(e.target.value) }} required />
                        <label htmlFor="phone1">* Phone Number</label>
                        <input type="number" id="phone1" value={phone1} onChange={(e) => { setPhone1(e.target.value) }} required />
                        <label htmlFor="email1">* Email</label>
                        <input type="email" id="email1" value={email1} onChange={(e) => { setEmail1(e.target.value.toLowerCase()) }} required />
                        <label htmlFor="confirm-email1">* Confirm Email</label>
                        <input type="email" id="confirm-email1" value={confirmEmail1} onChange={(e) => { setConfirmEmail1(e.target.value.toLowerCase()) }} required />
                        <label htmlFor="facebook1">Facebook</label>
                        <input type="text" id="facebook1" value={facebook1} onChange={(e) => { setFacebook1(e.target.value) }} />
                        <label htmlFor="instagram1">Instagram</label>
                        <input type="text" id="instagram1" value={instagram1} onChange={(e) => { setInstagram1(e.target.value) }} />
                    </div>
                    <h2>MEMBER 2</h2>
                    <div>
                        <label htmlFor="full-name2">* Full name</label>
                        <input type="text" id="full-name2" value={fullName2} onChange={(e) => { setFullName2(e.target.value) }} required />
                        <label htmlFor="nick-name2">* Nickname</label>
                        <input type="text" id="nick-name2" value={nickName2} onChange={(e) => { setNickName2(e.target.value) }} required />
                        <label htmlFor="phone2">* Phone Number</label>
                        <input type="number" id="phone2" value={phone2} onChange={(e) => { setPhone2(e.target.value) }} required />
                        <label htmlFor="email2">* Email</label>
                        <input type="email" id="email2" value={email2} onChange={(e) => { setEmail2(e.target.value.toLowerCase()) }} required />
                        <label htmlFor="confirm-email2">* Confirm Email</label>
                        <input type="email" id="confirm-email2" value={confirmEmail2} onChange={(e) => { setConfirmEmail2(e.target.value.toLowerCase()) }} required />
                        <label htmlFor="facebook2">Facebook</label>
                        <input type="text" id="facebook2" value={facebook2} onChange={(e) => { setFacebook2(e.target.value) }} />
                        <label htmlFor="instagram2">Instagram</label>
                        <input type="text" id="instagram2" value={instagram2} onChange={(e) => { setInstagram2(e.target.value) }} />
                    </div>
                    <p><input type="checkbox" id="confirmed-rules" checked={agree} onChange={(e) => { setAgree(e.target.checked) }} /> I agree to follow <b>Bachata Spice ttp UK Championship's</b> Rules and respect the decisions taken by the organization.</p>
                    {errorMessages.length > 0 ?
                        <ul>
                            {errorMessages.map((errorMessage, index) => <li className="error__message" key={"error-message-" + index}>{errorMessage}</li>)}
                        </ul>
                        :
                        <></>
                    }
                    {congratsMessage.length > 0 ?
                        <span className="congrats__message" >
                            {congratsMessage}
                        </span>
                        :
                        <></>
                    }
                    {/*warningMessages.length > 0 ?
                        <ul>
                            {warningMessages.map((warningMessage, index) => <li className="warning__message" key={"warning-message-" + index}>{warningMessage}</li>)}
                        </ul>
                        :
                        <></>
                    */}
                    <button>SUBMIT</button>
                </div>
            </form>
        </div >
    )
}

export default JoiningForm;