import React from "react";
import "./Home.scss";

const Home = () => {
  return (
    <div className="container">
      <div className="home__main">
        <div id="logo-bsaukc">
          <img
            src="/images/bsaukc-logo-2021-trnsprnt.png"
            alt="Logo Bachata Spice Amateur UK Championshipt 2021"
          />
        </div>
      </div>
      <div className="content__heats">
        <div id="c1">
          <div id="c11">
            <h2>
              <span className="black">BS</span>
              <span className="white">AUKC </span>{" "}
              <span className="black">2023 </span>
              <span className="white">HEATS</span>
            </h2>
            <span>
              <a className="events__link" href="/events">
                LEARN MORE
              </a>
            </span>
          </div>
        </div>
        <div id="c2"></div>
      </div>
      <div className="content__rules">
        <div id="cr1">
          <h2>COMPETITION RULES</h2>
          <p>
            On this page you will learn requirements to join and participate in
            Bachata Spice Amateur UK Championship 2023.
          </p>
          <span>
            <a id="rules-link" href="/rules">
              LEARN MORE
            </a>
          </span>
        </div>
      </div>
      <div className="content__enrollnow">
        <div id="ce1"></div>
        <div id="ce2">
          <div id="ce21">
            <h2>IT'S TIME FOR ACTION...</h2>
            <span>
              <a
                target="_blank"
                className="enroll__link"
                href="https://docs.google.com/forms/d/e/1FAIpQLSf-4Vos7YtVM_s1uvvkbaNvPohsIqlXwiWZMSwjtVIH1x6FpA/viewform"
              >
                ENROLL NOW
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="content__champions">
        <div id="c-ch-h2-div">
          <h2>DISCOVER OUR CHAMPIONS</h2>
        </div>
        <div id="champions">
          <div>
            <span>
              <a id="champions-link" href="/ourchampions">
                LEARN MORE
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="content__ourteam">
        <div id="c-o-h2-div">
          <h2>THIS IS OUR TEAM</h2>
        </div>
        <div id="ourteam">
          <div id="ourteam1"></div>
          <div id="ourteam2">
            <div>
              <span>
                <a className="ourteam__link" id="contact-link" href="#footer-1">
                  CONTACT US
                </a>
              </span>
              <span>
                <a className="ourteam__link" id="aboutus-link" href="/aboutus">
                  ABOUT US
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/*
       */}
    </div>
  );
};

export default Home;
