import React from "react";
import "./About.scss";
const About = () => {
  return (
    <div className="about__container">
      <h1>ABOUT US</h1>
      <div className="about">
        <div className="intro">
          <h2>ABOUT BACHATA SPICE AMATEUR UK CHAMPIONSHIP</h2>
          <p>
            Bachata Spice was born in 2011 as a partnership between{" "}
            <b>Peynao er Bachatero</b> and <b>Simon Lord Bachata</b> to organize
            BachataStars UK Championship. Since there, Bachata Spice has become
            the main organizer of Bachata Championships in the UK.
          </p>
          <p>
            In 2014 was run the first Spice BachataStars Championship, in 2015
            the first Bachata Spice UK Open Championship. But it was in{" "}
            <b>2016</b> when Peynao and Simon decided to start an Amateur
            Championship that would make more approchable to join the challenge
            of compiting for new bachateras and bachateros. And since then we
            have run 6 Bachata Spice Amateur Championships as part of our
            already 16 National and International competitions.
          </p>
          <img
            src="/images/about-bsaukc.png"
            alt="About Bachata Spice Amateur UK Championship image"
          />
          <p>
            This organisation is also very popular in the UK for pioneering
            Bachata parties in Saturday nights in London. This was an event that
            would run every first and third Saturday of the month and nowadays,
            it's been converted into{" "}
            <b>Pasión Bachata Party, every First Saturday of the month</b>.
          </p>
          <p>
            Follow Bachata Spice events and get ready to enjoy amazing events
            where every year we find and introduce to everyone the new Bachata
            talents from all over the country.
          </p>
        </div>
        <div className="organizers">
          <h2>THE ORGANIZERS</h2>
          Bachata Spice is a team of professional dancers and entertainers that
          create an unique concept of events where people get to know Bachata
          dance and music in an inclusive environment where anyone could join
          and have an amazing night of music and dance.
          {/*
                 <img src="/images/bsaukc-bosses.png" alt="About Bachata Spice Amateur UK Championship image" />
                 */}
          <div className="desc__peynao">
            <div className="peynao__text">
              <h3>Angel Peinado a.k.a. Peynao er Bachatero</h3>
              <p>
                Peynao, director of{" "}
                <a
                  rel="opener"
                  href="https://www.thelatinpassion.co.uk"
                  target="_blank"
                >
                  The Latin Passion
                </a>
                , is well known for being the{" "}
                <b>Pioneer of Sensual Bachata in the UK</b> and also for being a
                talented instructor, dancer, choreographer, performer, DJ and
                entertainer. With background in Latin dance back in Spain from
                many instructors and as main reference,{" "}
                <b>Korke &amp; Judith</b> for Bachata and{" "}
                <b>Rodrigo Torres and Carolina González</b> for Salsa, Peynao
                has earned the respect in the Latin dance community in the UK.
                Since he arrived to the country he has been the first one{" "}
                <b>
                  representing UK in 2011 in BachataStars Europe where he got to
                  the 4th position
                </b>{" "}
                with his then dance partner. He also won the Latin Dance Video
                competition for the Premiere of Street Dance 3D and is a
                well-known instructor, both nationally and internationally. He
                is currently teaching Bachata in fusion or separated in styles
                like Sensual, Modern, Traditional...
              </p>
              <p>
                Peynao has performed, taught and DJ in many national famous
                Latin dance clubs like Bachata Spice, El Grande, Bar Salsa,
                Latin Passion, Pasión, Motion City Festival, Sexy Latin
                Festival, Bachata Spice International Weekenders, BachataStars
                Europe Finals in London, Clave Latina Festival, Mambocity
                Congress, UKDC... and cities like Edimburgh, Nottingham, Oxford,
                Brighton, Cambridge... and has been one of the very first UK
                based Bachata artists traveling abroad: Dominican Republic
                (Bachata Paradise), Madrid Bachatea Festival in Spain, Huelva
                Kizombachata Festival also in Spain, Ballagan Festival in
                Israel, Italy, Hungary, Ireland…
              </p>
              <p>
                He has also organised concerts of singers as{" "}
                <b>Ephrem J, Dani J and Grupo Extra</b> and proudly judged in
                Euro Qualifying heats of the World Latin Championship: Euroson
                Latino, co-organized Bachata Spice events and worked as creative
                director in many of these events.
              </p>
              <div className="contact__div">
                <span>
                  <a className="contact" href="#footer-1">
                    CONCTACT PEYNAO
                  </a>
                </span>
              </div>
            </div>
            <div className="peynao__picture"></div>
            {/*
             */}
          </div>
          <div className="desc__miriam">
            <div className="miriam__picture"></div>
            <div className="miriam__text">
              <h3>New co-director - Miriam Faura</h3>
              <p>
                Director, director of Race &amp; Rhythm , is our new co-director
                of Bachata Spice Events.
              </p>
              <p>
                UK Choreographer, Flamenco contemporary choreographer, Miriam
                Faura was born in Madrid, Spain. At the age of five she started
                training in Flamenco, Classical Spanish and Ballet. Miriam's
                interest in choreography started at the early age of 14 when she
                choreographed for a group of children from the "EL Cid" Primary
                school taking part in community center festivals in Madrid. At
                the same time Miriam joined Marta de la Vega dance school. Here
                she was trained in Ballet, Folk, Flamenco and Classical Spanish
                for The Royal Conservatory of Dance exams in Madrid and finally
                Miriam completed her Flamenco dance studies in the Flamenco
                School "Amor de Dios", while also studying A Levels, Access to
                the University and a BA in Business in Madrid. Miriam moved to
                London where she studied Contemporary dance, Jazz, African, Hip
                Hop and Salsa while working as a Flamenco soloist performing her
                own choreography with different groups. She completed a Theater
                access course at Hoxton Hall and with a scholarship from the EU
                she gained a BA in Performing Arts at Middlesex University where
                she created her first two pieces "Oceano" and "Agresion". Miriam
                combined her studies while working as a professional performer
                in different styles and events from small productions and venues
                like La Pena Flamenca to large-scale events at The Royal Albert
                Hall and The Royal Opera House... Info to be updated soon.
              </p>
              <div className="contact__div">
                <span>
                  <a className="contact" href="#footer-1">
                    CONTACT MIRIAM
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="desc__simon">
            <div className="simon__picture"></div>
            <div className="simon__text">
              <h3>
                Former co-director - Simon Ofoborh a.k.a. Simon Lord Bachata
              </h3>
              <p>
                Simon, director of{" "}
                <a
                  rel="noopener"
                  href="https://www.facebook.com/latindancecartel"
                  target="_blank"
                >
                  Latin Dance Cartel
                </a>
                , has been dancing and entertaining since he was 8 years old.
                Disco and Brake Dancing was previously his fortes. His dancing
                continued on a more serious level throughout his teenage years,
                directing and leading various dance projects, including
                recreating Michael Jackson’s Thriller routine. He also had a
                passion for sports, Gymnastics and Swimming which he
                participated in alongside his dancing.
              </p>
              <p>
                He took his first Salsa class in January 2004 and still loves
                it. He spent the first 14 months just learning and social
                dancing, getting to know what salsa was all about. With almost
                two years of Salsa under his belt, he developed into a smooth
                dancer with a very precise lead. In 2006 he discovered Bachata
                which he loved and was shortly recognized for his smooth and
                funky style. That same year he was placed in the top 3 at the
                British Amateur Salsa Contest. In 2007 he won the European Open
                Bachata Championship with former Dance Partner (Angelique
                Sinclair of Seraphim Dance UK) at the UK Salsa Congress.
              </p>
              <p>
                In 2009, he discovered Bachatango and in 2010 he joined forces
                with Natalie J Watterson. From then went on to teach and
                performed at various events and festivals around the UK. In 2012
                Simon worked on a couple of projects with one of his up and
                coming student Nelix While Natalie was on a break.
              </p>
              <p>
                Joined forces again with Natalie J Watterson in 2014 They run
                student performances and take their workshops and performances
                around the UK and Europe. They have worked at congresses and
                festivals with the likes of Mambo City, The Latin Collective,
                UKDC, I Love Bachata (Sexy Latin Dance Festival), love2Salsa (El
                Grande) Latin Motion (Motion City Congress) and many more.
              </p>
              <p>
                Simon is the founder and Director of London Dance Cartel,
                Bachatango UK and Lord Bachata Dancers which he runs with
                Natalie J Watterson. Their disciplines involve teaching and
                performing Salsa (Mambo/Salsa on 1-2), Bachata
                (Moderna/Sensual), Tango, Bachatango and Cha Cha Cha.
              </p>
              <p>
                He is also the Co-Founder of Bachata Spice, former co-director
                of Bachata Spice Events and 2013's Coordinator of BachataStars
                UK/Europe
              </p>
              <div className="contact__div">
                <span>
                  <a className="contact" href="#footer-1">
                    CONCTACT SIMON
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
