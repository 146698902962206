import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import "./Event.scss";

const Event = () => {
  const [event, setEvent] = useState({});
  const { id } = useParams();
  const getEvent = async () => {
    try {
      //const response = await fetch(`https://` + window.location.hostname + `:5000/events/${id}`);
      const response = await fetch(
        `https://server.bsaukc.uk/bsaukc-server/events/${id}`
      );
      const jsonData = await response.json();
      console.log("jsonData:", jsonData);
      setEvent(jsonData);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  function createMarkup(mapiframe) {
    //console.log("mapiframe: ", mapiframe);
    return {
      __html: mapiframe,
    };
  }

  const listOrganizers = (organizers) => {
    const arrOrganizers = organizers.split(",");
    if (arrOrganizers.length === 1) {
      return arrOrganizers[0] + ".";
    } else {
      return arrOrganizers.map((organizer, index) => {
        if (index === 0) {
          return organizer;
        } else if (index < arrOrganizers.length - 1) {
          return ", " + organizer;
        } else {
          return " and " + organizer + ".";
        }
      });
    }
  };

  return (
    <div className="event__container">
      <h1>{(event.name + "").toUpperCase()}</h1>
      <img
        className="event__div__img"
        alt={event.cover}
        src={"/images/" + event.cover}
      />
      <p className="date">
        {"" + event.starts === "2021-10-29T21:23:23.000Z"
          ? "(Date TBC)"
          : "(On " + new Date(event.starts).toDateString() + ")"}
      </p>
      <p className="desc">{event.description}</p>
      <p className="address">Address: {event.address}</p>
      <div
        className="mapDiv"
        dangerouslySetInnerHTML={createMarkup(event.mapiframe)}
      ></div>
      <p className="know__more__p">
        <a target="_blank" rel="noopener noreferrer" href={event.url}>
          Click here to know more about the event hosting this heat.
        </a>
      </p>
      {event.organizers && (
        <p className="desc">
          This is an event organized by: {listOrganizers(event.organizers)}
        </p>
      )}
      <Link to="/events" className="return-button">
        <img src="/images/back-button.png" alt="Back to events page" />
      </Link>
    </div>
  );
};

export default Event;
