import React from "react";
import "./OurChampions.scss";
import ReactPlayer from "react-player";
//import YoutubeEmbed from './YoutubeEmbed';

const OurChampions = () => {
  return (
    <div className="container__our__champions">
      <h1>GET TO KNOW OUR CHAMPIONS</h1>
      <div className="our__champions">
        <h2>2019 CHAMPIONS - EVA PUCHI &amp; VISHAL DHAMI</h2>
        <div className="couple__odd">
          <div className="first__paragraph">
            {/*
                        <p>
                            In 2019 Vishal &amp; Eva became our latest Bachata Spice Amateur Champions.<br />
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <div className="eva__vishal__image"></div>
                        <img src="/images/eva-puchi-vishal-dhami-bsaukc-2019.png" alt="Eva and Vishal" />
                    */}
            <div className="eva__vishal__image"></div>
          </div>
          <p>Detailed information about our champions is coming soon...</p>
          <h3>YOU CAN FOLLOW THEM ON</h3>
          <div className="links__div">
            <div>
              <ul>
                <li key="li-our-champs-'01">Eva Puchi</li>
                <li key="li-our-champs-'011">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/evapuchi.bachata/"
                  >
                    <img
                      id="ep-in"
                      src="/images/instagram.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
                <li key="li-our-champs-'02">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/eva.puchi.79/"
                  >
                    <img
                      id="ep-fb"
                      src="/images/facebook.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li key="li-our-champs-'03">Vishal Dhami</li>
                <li key="li-our-champs-'04">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/vishal.dhami/"
                  >
                    <img
                      id="ep-in"
                      src="/images/instagram.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
                <li key="li-our-champs-'05">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/vishal.dhami.23"
                  >
                    <img
                      id="ep-fb"
                      src="/images/facebook.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="youtube__video">
            <div id="vishal-eva-video">
              <a
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=kr0uFaHLIww&amp;list=PLdJUg8jH24PcZXCp9U9SRjWrClVJbrwar&amp;index=1"
                target="_blank"
              >
                <img src="/images/play.png" alt="play button" />
              </a>
            </div>
          </div>
        </div>
        <h2>2018 CHAMPIONS - ANISSA PEPITA &amp; ANTONIO STELLA</h2>
        <div className="couple__odd">
          <div className="first__paragraph">
            {/*
                        <p>
                        In 2019 Vishal &amp; Eva became our latest Bachata Spice Amateur Champions.<br />
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <div className="eva__vishal__image"></div>
                        <img src="/images/eva-puchi-vishal-dhami-bsaukc-2019.png" alt="Eva and Vishal" />
                    */}
            <div className="anissa__antonio__image"></div>
          </div>
          <p>Detailed information about our champions is coming soon...</p>
          <h3>YOU CAN FOLLOW THEM ON</h3>
          <div className="links__div">
            <div>
              <ul>
                <li key="li-our-champs-'06">Anissa Pépîtà</li>
                <li key="li-our-champs-'07">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/p/CON_qm_hQ19/"
                  >
                    <img
                      id="ep-in"
                      src="/images/instagram.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
                <li key="li-our-champs-'08">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=100011108490365"
                  >
                    <img
                      id="ep-fb"
                      src="/images/facebook.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li key="li-our-champs-'09">Antonio Stella</li>
                <li key="li-our-champs-'091">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/jud0k4m4n/"
                  >
                    <img
                      id="ep-in"
                      src="/images/instagram.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
                <li key="li-our-champs-'10">
                  <a target="_blank" href="https://www.facebook.com/Judokaman">
                    <img
                      id="ep-fb"
                      src="/images/facebook.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="youtube__video">
            <div id="video-antonio-anissa">
              <a
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=KLsThejJYQk&amp;list=PLdJUg8jH24PfQVheU1x2h2iN0rf3Ejf2G"
                target="_blank"
              >
                <img src="/images/play.png" alt="play button" />
              </a>
            </div>
          </div>
        </div>
        <h2>2017 CHAMPIONS - VERONKA SIMONOVA &amp; CEDRIC HENRY</h2>
        <div className="couple__odd">
          <div className="first__paragraph">
            {/*
                        <p>
                        In 2019 Vishal &amp; Eva became our latest Bachata Spice Amateur Champions.<br />
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <div className="eva__vishal__image"></div>
                        <img src="/images/eva-puchi-vishal-dhami-bsaukc-2019.png" alt="Eva and Vishal" />
                    */}
            <div className="veronika__cedric__image"></div>
          </div>
          <p>Detailed information about our champions is coming soon...</p>
          <h3>YOU CAN FOLLOW THEM ON</h3>
          <div className="links__div">
            <div>
              <ul>
                <li key="li-our-champs-'11">Veronika Simonova</li>
                <li key="li-our-champs-'12">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/veronika_simo_nova/"
                  >
                    <img
                      id="ep-in"
                      src="/images/instagram.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
                <li key="li-our-champs-'13">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/veronika.simonova.7505"
                  >
                    <img
                      id="ep-fb"
                      src="/images/facebook.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li key="li-our-champs-'14">Cédric Henry</li>
                <li key="li-our-champs-'15">
                  <a target="_blank" href="https://www.instagram.com/henryced/">
                    <img
                      id="ep-in"
                      src="/images/instagram.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
                <li key="li-our-champs-'16">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/henrycedrik"
                  >
                    <img
                      id="ep-fb"
                      src="/images/facebook.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="youtube__video">
            <div id="video-cedric-veronika">
              <a
                rel="noreferrer"
                href="https://www.facebook.com/watch/1412834232282789/491142271989408"
                target="_blank"
              >
                <img src="/images/play.png" alt="play button" />
              </a>
            </div>
          </div>
        </div>
        <h2>2016 CHAMPIONS - EVELYN PERERA &amp; JAMES CAULFIELD</h2>
        <div className="couple__odd">
          <div className="first__paragraph">
            <div>
              <h4>How was your way into dance?</h4>
              <p>
                <b>Evelyn</b> started dancing at the age of 4, taking lessons in
                ballet and jazz and eventually learning salsa and bachata at
                university. She was lucky enough to count people such as Daniel
                &amp; Desire and Korke &amp; Judith among her first teachers in
                Spain. <br />
                <b>James</b> was more focused on martial arts classes until
                moving to London at 26. However, dancing was something he'd
                always wanted to learn so started going to salsa classes.
                Dancing and martial arts are very similar in a lot of ways and
                made picking up the movements a lot easier. Also helps avoid
                those flying elbows on the dance floor. A few years later at a
                salsa festival in Croatia he went to a bachata room for a rest
                from all the salsa, fell in love with the music and didn't leave
                that room for the rest of the festival. Eventually we met at the
                audition for the Juan Soto Flava Bachata performance group where
                Juan put us together. That was the start of an amazing journey
                for us.
              </p>
            </div>
            <div className="evelyn__james__image"></div>
            {/*
                        <img src="/images/evelyn-perera-james-caulfield-bsaukc-2016.png" alt="Eva and Vishal" />
                        <div className="evelyn__james__image"></div>
                    */}
          </div>
          <h4>
            Why did you decide to compete at Bachata Spice Amateur Championship
            and which challenges did you find?
          </h4>
          <p>
            After taking classes and social dancing for a number of years it's
            normal to want to try something new to improve and test yourself. So
            we both ended up joining the Juan Soto Flava Bachata performance
            team, which is where we met. We loved dancing together so much that
            when we heard about the Bachata Spice Amateur Championship we had to
            try out for it. For us it was the perfect next challenge. It was
            difficult though. Performing a set choreo is completely different to
            performing to a random song you may never have heard before and
            again the skills needed for competing are different to social
            dancing and performing. We took private lessons from a number of
            amazing teachers to help us prepare and practised every evening that
            we were free. The main challange was trying to fit in all this
            practise around the group rehearsing we were still doing with the
            Flava team. In fact, on the day of the final for the competition we
            had just flown back from Madrid after performing with the team.
          </p>
          <h4>
            How was the experience of becoming a Bachata Spice Champion? How did
            it affect your dance careers?
          </h4>
          <p>
            Winning the Bachata Spice Amateur Championship, and later going on
            to win the BachataStars UK Championship, were two of the best
            moments of our lives. That feeling when all the work, time and
            effort that you've put in finally pays off is incredible. It gave us
            the confidence and experience needed to enter other competitions.
            Bachata Spice also gave us the chance to teach at some of their
            events after winning and this is what launched our dance career as
            teachers and performers. The exposure from the competition got us
            invited to teach and perform in a number of other events and
            festivals. For us, the Bachata Spice competition is where it all
            started and we're so thankful to them for running this competition
            and all the help and support they've provided us afterwards.
          </p>
          <h4>What do you do nowadays dance wise?</h4>
          <p>
            Unfortunately, Covid has had a massive impact on the whole dance
            community and for the last year and half all social dancing and
            teaching in person has stopped. We've been very lucky though to be
            able to continue teaching together online as part of Hollywood
            Productions where we've been running beginner, intermediate and
            advanced classes. In addition, Evelyn has been teaching a number of
            ladies styling classes online, both by herself and in group events.
            She has also put together her own ladies styling team and so far
            they've recorded 2 professional videos. With the Covid restrictions
            lifting, Evelyn has now started teaching ladies styling classes in
            the studio.{" "}
          </p>
          <h3>YOU CAN FOLLOW THEM ON</h3>
          <div className="links__div">
            <div>
              <ul>
                <li key="li-our-champs-'17">Evelyn Perera</li>
                <li key="li-our-champs-'18">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/evelyndancefit/"
                  >
                    <img
                      id="ep-in"
                      src="/images/instagram.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
                <li key="li-our-champs-'19">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/evelyn.pereramachin"
                  >
                    <img
                      id="ep-fb"
                      src="/images/facebook.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li key="li-our-champs-'20">James Caulfield</li>
                <li key="li-our-champs-'21">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/jwcaulfield/"
                  >
                    <img
                      id="ep-in"
                      src="/images/instagram.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
                <li key="li-our-champs-'22">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/jwcaulfield"
                  >
                    <img
                      id="ep-fb"
                      src="/images/facebook.png"
                      alt="Champion Instagram Link"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="youtube__video">
            <div id="video-james-evelyn">
              <a
                rel="noreferrer"
                href="https://www.facebook.com/bachataspice/videos/1891714527728088"
                target="_blank"
              >
                <img src="/images/play.png" alt="play button" />
              </a>
            </div>
          </div>
          <div className="other__links">
            <h3>OTHER LINKS</h3>
            <a
              rel="noopener"
              target="_blank"
              href="https://www.facebook.com/evelyn.pereramachin"
            >
              <i className="bullet"></i> James &amp; Evelyn on Facebook
            </a>
            <a
              rel="noopener"
              target="_blank"
              href="https://www.youtube.com/channel/UCsNAnWkA5tminNqgHE3vQQg"
            >
              <i className="bullet"></i> James &amp; Evelyn on Youtube
            </a>
            <a
              rel="noopener"
              target="_blank"
              href="https://www.youtube.com/channel/UC3k8ZUebXk_WFHca2S0IWeQ"
            >
              <i className="bullet"></i> Evelyn Dancefit on Youtube
            </a>
            <a
              rel="noopener"
              target="_blank"
              href="https://www.evelyndancefit.com"
            >
              <i className="bullet"></i> www.evelyndancefit.com
            </a>
            <a
              rel="noopener"
              target="_blank"
              href="https://www.facebook.com/Hollywood-Bachata-102215558366905"
            >
              <i className="bullet"></i> Hollywood Bachata on Facebook
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurChampions;
