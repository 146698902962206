import React from "react";
import "./Rules.scss";
import { Link } from "react-router-dom";

const Rules = () => {
  return (
    <div className="rules">
      <h1>RULES</h1>
      <div className="rules__container">
        <div className="list__of__rules">
          <h2>1&#41; ELIGIBILITY TO COMPETE</h2>
          <p>
            <i className="bullet"></i>Bachata Spice UK Amateur Championship is a
            National UK Bachata competition.
          </p>
          <p>
            <i className="bullet"></i>Every couple must be formed by two people.
          </p>
          <p>
            <i className="bullet"></i>One of the members of the couple must have
            resided in the country for over 2 years and the second member at
            least for 6 months. If the couple doesn’t apply with these
            conditions, then, both of them must have resided in the UK for over
            1 year.
          </p>
          <p>
            <i className="bullet"></i>This is a{" "}
            <b>Purely Amateur Competition</b>. That means that, if anyone can
            prove that one of the members of couples have ever taught publicly
            or been part of a professional dance team or solo artist, the couple
            will be removed from the competition. Sometimes it’s difficult to
            say if one member of a couple is semi-pro, pro or amateur. For this
            reason, we will review every qualified couple before the UK Finals
            and will accept complains about possible “invalid couples” up to the
            26<sup>th</sup> of September 2023.
          </p>
          <p>
            <i className="bullet"></i>No couples who got a podium position in
            any UK Bachata competition will be allowed to compete.
          </p>

          <h2>2&#41; FORMAT</h2>
          <p>
            <i className="bullet"></i>The Finals of the competition will be held
            on Saturday 7<sup>th</sup> October 2023 at Pasión All Dayer.
          </p>
          <p>
            <i className="bullet"></i>There will be qualifying heats around the
            country organised by local promoters with the collaboration of
            Bachata Spice Organization (
            <Link to="https://www.thelatinpassion.co.uk">
              The Latin Passion
            </Link>{" "}
            and <span>Race &amp; Rhythm</span>).
          </p>
          <p>
            <i className="bullet"></i>On each heat two couples will qualify to
            for the Finals.
          </p>
          <p>
            <i className="bullet"></i>Qualified couples will go straight to the
            finals and won’t be permitted to take part in any other different
            heat unless the couple is broken. In that case the members of the
            couples will be allowed to compete paired with other people and the
            3<sup>rd</sup> position couple of their heat will be invited to the
            finals.
          </p>
          <p>
            <i className="bullet"></i>This completion will be improvisation
            only.
          </p>
          <p>
            <i className="bullet"></i>No acrobatics or full splits will be
            allowed. We understand acrobatics as the moment when one of the
            members is not in contact with the floor to execute some kind of
            lift or aerial movement.
          </p>
          <p>
            <i className="bullet"></i>Routines Duration: In the qualifying heats
            each couple will dance between 2,5 and 3,5 minutes depending on the
            number of couples and the local organization. For that reasons
            couples may dance on their own or up to two couples at the same time
            and exceptionally three couples at the same time.
          </p>
          <p>
            <i className="bullet"></i>The songs will be randomly chosen by the
            organizers, trying to always play popular songs in any of the
            styles.
          </p>
          <p>
            <i className="bullet"></i>Each couple can execute a maximum of 3
            tricks (remember no acrobatics or full splits) and 4 tricks in the
            UK Finals. Our definition of trick: Any movement in which one of the
            members of the couple will require the help or support of the other
            one to keep the balance while executing it.
          </p>
          <p>
            <i className="bullet"></i>In the finals there will be 2 rounds if
            necessary. In that case each couple qualifying to the second round
            will dance one more time.
          </p>

          <h2>3&#41; JUDGING CRITERIA</h2>
          <p>
            <i className="bullet"></i>
            <span>Presentation:</span> Attitude, presence and dressing match.
            The judges will appreciate if they competitors combine their
            dressing, however it’s going to be only a 30% of this criteria and
            no costumes will be allowed. Stones will only be allowed in shoes
            but these won’t be taken in consideration while scoring the
            dressing, rather than by the colour match with the rest of the
            costume.
          </p>
          <p>
            <i className="bullet"></i>
            <span>Musicality:</span> Timing, rhythm and music interpretation.
          </p>
          <p>
            <i className="bullet"></i>
            <span>Technique:</span> Ability of executing clean movements, body
            posture while executing.
          </p>
          <p>
            <i className="bullet"></i>
            <span>Connection:</span> Interaction with partner, synchronisation
            and leading/following.
          </p>
          <p>
            <i className="bullet"></i>
            <span>Spice:</span> Latin/Caribbean flavour in the dance and
            audience engagement.
          </p>

          <h2>4&#41; PRIZE</h2>
          <p>
            <i className="bullet"></i>
            <span>Champions:</span> £500 CASH + 1-year Free entrance to all the
            regular Bachata Spice events + qualifying to our next UK Open
            Championship.
          </p>
          <p>
            <i className="bullet"></i>
            <span>2nd and 3rd position:</span> 2-month Free entrance to all the
            regular Bachata Spice events + qualifying to our next UK Open
            Championship (under review of Bachata Spice organizers).
          </p>

          <h2>5&#41; DECISION OF JUDGES</h2>
          <p>
            <i className="bullet"></i>All competitors will accept the decisions
            of judges and the organising committee as FINAL.{" "}
          </p>
          <p>
            <i className="bullet"></i>Any expression of dislike or aggression
            (or supporting anyone executing these actions) towards the judges,
            organizers, public, sponsors and / or any person or entity related
            to Bachata Spice Championship, by dancers, coaches and / or members
            of their families is strictly <span>FORBIDDEN</span> and could be
            taken as reason of disqualification.
          </p>
          <p>
            <i className="bullet"></i>All competitors have the right to ask for
            a review of the results of the competition during the day of the
            competition.
          </p>

          <h2>6&#41; JOINING THE COMPETITION</h2>
          <p>
            <i className="bullet"></i>The minimum number of couples needed to
            execute each heat of this competition is 3.
          </p>
          <p>
            <i className="bullet"></i>To join the competition, the participants
            must fill the official form on this{" "}
            <Link
              target="_blank"
              to="https://docs.google.com/forms/d/e/1FAIpQLSf-4Vos7YtVM_s1uvvkbaNvPohsIqlXwiWZMSwjtVIH1x6FpA/viewform"
            >
              LINK
            </Link>
            .
          </p>

          <h2>7&#41; COPYRIGHT</h2>
          <p>
            <i className="bullet"></i>All competitors authorise the use of their
            images for promotion of the event on video, image, text or audio
            format. All the images and videos from the competition are property
            of Bachata Spice organizers (
            <Link to="https://www.thelatinpassion.co.uk">
              The Latin Passion
            </Link>{" "}
            and <span>Race &amp; Rhythm</span>) and collaborators of any of
            their events (example, Pasión organisers for the UK Finals event).
          </p>

          <h2>8&#41; CHANGES</h2>
          <p>
            <i className="bullet"></i>The organization reserves the right to
            change any of the rules in benefit of a fair and better running of
            the competition/event.
          </p>
          <h2>9&#41; 2023 UPDATES - OUR MAIN GOAL!</h2>
          <p>
            <i className="bullet"></i> Bachata Spice Amateur UK Championship's
            main Goal is to create a platform for UK Bachater@s to develop and
            show their talents. The final results of all the effort of
            competitors and organisers are shown on our All Dayer and UK Finals,
            on a day where Bachater@s from all around the country get together
            to celebrate their love for Bachata and support our new talents. On
            that day, competitors and organisers will have done all their best
            to reach this event and everyone must understand that at that point{" "}
            <span>THE SHOW MUST GO ON</span> and{" "}
            <span>WE ALL MUST COLLABORATE</span> to get the job done. If at this
            point we find that any of the competitors is not ready to accept the
            results of this competition or is acting against the interest of
            this event, the organisation will reserve the right to invite them
            to abandon the competition.
            <br />
            We believe that this is only fair to ask for and no more than the
            preparation for our competitors to become real dance artists. <br />
            With this, we wish them all the best luck and a beautiful time
            competing at our championship.
          </p>
        </div>
        <div className="picture"></div>
      </div>
    </div>
  );
};

export default Rules;
