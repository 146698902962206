import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import "./Events.scss";

const Events = () => {
  const [events, setEvents] = useState([]);
  const getEvents = async () => {
    try {
      //const response = await fetch("https://" + window.location.hostname + ":5000/events");
      const response = await fetch(
        "https://server.bsaukc.uk/bsaukc-server/events"
      );
      const jsonData = await response.json();

      console.log("jsonData: ", jsonData);

      setEvents(jsonData);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className="events">
      <h1>OUR COMING HEATS</h1>
      <div className="events__container">
        {events.map((event) => {
          return (
            <div className="event__div" key={"heat" + event.id}>
              <h2 className="text-center">{event.name}</h2>
              <p className="date">
                {" "}
                {"" + event.starts === "2021-10-29T21:23:23.000Z"
                  ? "(Date TBC)"
                  : "(On " + new Date(event.starts).toDateString() + ")"}
              </p>
              <p className="desc">{event.summary}</p>
              <span>
                <Link className="event__link" to={"/event/" + event.id}>
                  LEARN MORE
                </Link>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Events;
