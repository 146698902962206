import "./App.scss";
import { Route, Switch } from "react-router-dom";
import Events from "./components/Events";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Event from "./components/Event";
import Home from "./components/Home";
import About from "./components/About";
import Rules from "./components/Rules";
import JoiningForm from "./components/JoiningForm";
import OurChampions from "./components/OurChampions";
//import ReturnToTopArrow from './components/ReturnToTopArrow';
import DanceClasses from "./components/DanceClasses";

function App() {
  return (
    <div className="App">
      {/*
       */}
      <Header />
      <Switch>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/events">
          <Events />
        </Route>
        <Route exact path="/event/:id">
          <Event />
        </Route>
        <Route exact path="/aboutus">
          <About />
        </Route>
        <Route exact path="/rules">
          <Rules />
        </Route>
        <Route exact path="/joiningform">
          <JoiningForm />
        </Route>
        <Route exact path="/ourchampions">
          <OurChampions />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
      <DanceClasses />
      <Footer />
      {/*
      <ReturnToTopArrow />

       */}
    </div>
  );
}

export default App;
