import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div id="footer-1">
        <h2>CONTACT</h2>
        <ul>
          <li key="li-foot-'01">
            <a target="_blank" href="https://www.facebook.com/peynao">
              Peynao er Bachatero (Angel Peinado)
            </a>
          </li>
          <li key="li-foot-'02">
            <a target="_blank" href="tel:00447429147775">
              +44 7429 147775
            </a>
          </li>
          <li key="li-foot-'03">
            <a target="_blank" href="mailto:info@thelatinpassion.co.uk">
              info@thelatinpassion.co.uk
            </a>
          </li>
        </ul>
        <ul>
          <li key="li-foot-'04">
            <a
              target="_blank"
              href="https://www.facebook.com/simon.lordbachata"
            >
              Miriam Faura
            </a>
          </li>
          <li key="li-foot-'05">
            <a target="_blank" href="tel:00447883066835">
              +44 7883 066835
            </a>
          </li>
          <li key="li-foot-'06">
            <a target="_blank" href="mailto:competition@bachataspice.com">
              competition@bachataspice.com
            </a>
          </li>
        </ul>
      </div>
      <div id="footer-2">
        <h2>BSAUKC 2023</h2>
        <p>
          This competition is organized by <i>Bachata Spice</i>, an association
          between{" "}
          <a target="_blank" href="https://www.thelatinpassion.co.uk">
            The Latin Passion
          </a>{" "}
          and Race &amp; Rhythm.
        </p>
      </div>
      <div id="footer-3">
        <h2>NEWSLETTER</h2>

        <p>If you are interested in more amazing Bachata Spice events:</p>

        <span>
          <a
            rel="noopener"
            className="joinus__link"
            href="https://eepurl.com/3ZSZf"
            target="_blank"
          >
            JOIN US
          </a>
        </span>
      </div>
      <div id="footer-4">
        <h2>FOLLOW US</h2>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/bachataspice/"
              target="_blank"
              rel="noopener"
            >
              <img
                id="fb"
                src="images/facebook.png"
                alt="Bachata Spice Facebook Page"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.instagram.com/bachataspice/">
              <img
                id="ig"
                src="/images/instagram.png"
                rel="noopener"
                alt="Bachata Spice Instagram Page"
              />
            </a>
          </li>
        </ul>
      </div>
      <div id="footer-5">
        <p>
          <a rel="noopener" target="_blank" href="https://www.bachataspice.com">
            Bachata Spice
          </a>{" "}
          &copy; 2011 - 2023 <span id="vbar">|</span> Website Designed by{" "}
          <a href="mailto:dcbarrigan@gmail.com" target="_blank" rel="noopener">
            Barrigan Media
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
